<template>
    <div class="elem-upload-box" dark-class="elem-upload-box-dark">
        <input ref="upload_input" class="upload-input" type="file" :accept="accept || types[type].accept" :multiple="length > 1" @change="onSelectFile" />
        <div ref="upload_drag" class="upload-drag-box" v-show="length > files.length" @click="onClickUpload">
            <div class="icon-box">
                <Icon class="icon" :type="types[type].icon" color="#c0c0c0" size="50" />
            </div>
            <div class="name">点击上传，最多可上传 {{ length }} {{ types[type].unit }}{{ types[type].name }}</div>
        </div>
        <div class="upload-preview-box" v-if="files.length > 0" :style="{ 'margin-top': files.length > 0 && length > files.length ? '10PX' : '0' }">
            <div class="preview-title">预览：</div>
            <div class="upload-preview-list">
                <div class="upload-file-box" v-for="(item, idx) in files" :key="idx">
                    <div v-if="type === 'image'" class="upload-image-base">
                        <img :src="item.preview || item.url || item.src" class="upload-image" mode="aspectFill" />
                    </div>

                    <div v-else-if="type === 'video'" class="upload-video-base">
                        <video v-if="type === 'video'" :src="item.preview || item.url || item.src" class="upload-video" controls></video>
                    </div>

                    <div v-else-if="type === 'audio'" class="upload-audio-base">
                        <audio v-if="type === 'audio'" :src="item.preview || item.url || item.src" class="upload-audio" controls></audio>
                    </div>

                    <div v-else-if="type === 'file'" class="upload-file-base">
                        <Icon class="icon" type="md-document" color="#2faaf7" size="40" />
                    </div>

                    <div class="upload-file-operating">
                        <div class="upload-file-operating-move">
                            <div class="upload-file-operating-button upload-file-operating-move-before" @click="moveUpload(item.id, 'move-before')">
                                <Icon class="icon" type="ios-arrow-back" />
                            </div>
                            <div class="upload-file-operating-button upload-file-operating-move-after" @click="moveUpload(item.id, 'move-after')">
                                <Icon class="icon" type="ios-arrow-forward" />
                            </div>
                        </div>
                        <div class="upload-file-operating-button" @click="deleteUpload(item.id)">
                            <Icon class="icon" type="ios-trash" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Utils from "../utils/utils"

export default {
    data() {
        return {
            files: [],
            types: {
                image: {
                    name: "图片",
                    accept: "image/*",
                    icon: "md-images",
                    unit: "张",
                },
                video: {
                    name: "视频",
                    accept: "video/*",
                    icon: "md-camera",
                    unit: "个",
                },
                audio: {
                    name: "音频",
                    accept: "audio/*",
                    icon: "md-musical-note",
                    unit: "条",
                },
                file: {
                    name: "文件",
                    accept: "",
                    icon: "md-document",
                    unit: "个",
                },
            },
        }
    },

    props: {
        value: {
            type: [Array, Object, String],
            default: () => [],
        },
        name: String,
        type: {
            type: String,
            default: "image",
        },
        title: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        length: {
            type: Number | String,
            default: () => 1,
        },
        size: String,
        filename: {
            type: String,
            default: "",
        },
        folder: {
            type: String,
            default: "",
        },
        accept: {
            type: String,
            default: "",
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        files(value) {
            this.$emit("on-change", {
                tag: "ElemUpload",
                name: this.name,
                value: value,
            })
        },

        value: {
            handler(value, old) {
                if (value === old) return
                if (!value) return (this.files = [])

                if ("string" === typeof value) {
                    if (/^\[.*]$/.test(value)) {
                        value = JSON.parse(value).map(v => ({ src: v }))
                    } else
                        value = [
                            {
                                src: value,
                            },
                        ]
                } else if (!(value instanceof Array)) {
                    value = [value]
                } else {
                    value = value.map(v => {
                        if (typeof v === "string") {
                            return { src: v }
                        }
                        return v
                    })
                }

                var files = []

                Utils.each(value, v => {
                    files.push({
                        id: Utils.getUuid(),
                        ...v,
                    })
                })

                this.files = files
            },
            immediate: true,
        },
    },

    mounted() {
        this.$emit("on-load", {
            tag: "ElemUpload",
            type: "on-load",
            name: this.name,
            value: this,
        })
    },

    methods: {
        onClickUpload() {
            if (!this.disabled) {
                this.$refs.upload_input.click()
            }
        },

        /**
         * 清空
         */
        onEmpty() {
            // 请空文件列表
            this.files = []
            // 清空 input 值
            this.$refs.upload_input.value = null
        },

        onSelectFile(evt) {
            this.processFiles((evt.target || evt.srcElement).files)
        },

        /**
         * 处理批量文件
         */
        async processFiles(files) {
            for (let i = 0; i < files.length; i++) {
                await this.processFile(files[i])
            }

            // 清空 input 值
            this.$refs.upload_input.value = null
        },

        /**
         * 处理单个文件
         */
        processFile(file) {
            return new Promise((resolve, reject) => {
                const config = this.types[this.type]

                if (!new RegExp(config.accept).test(file.type)) {
                    this.$Message.error(`不支持当前文件类型，仅支持 ${config.name} 类型`)
                    return reject()
                }

                var reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onerror = err => {
                    this.$Message.error("文件加载失败")
                    reject(err)
                }

                reader.onload = async () => {
                    if (this.files.length > this.length - 1) {
                        this.$Message.info(`最多只能上传${this.length}个文件`)
                        return reject()
                    }

                    var res = reader.result
                    var blob

                    if (this.type === "image" && Utils.isExist(this.size)) {
                        let data = await this.editImageByFile(res, this.size)

                        res = data.dataURL

                        if (data.blob) {
                            blob = data.blob
                        }
                    }

                    this.files.push({
                        id: Utils.getUuid(),
                        src: res,
                        name: file.name,
                        initial: true,

                        ...(blob ? { blob } : { file }),
                    })

                    resolve()
                }
            })
        },

        moveUpload: function(id, type) {
            Utils.each(this.files, v => (v.id === id ? type : null))
        },

        /**
         * 删除资源文件
         */
        deleteUpload(id) {
            this.$Modal.confirm({
                title: "提示",
                content: "是否确认删除该文件？",
                onOk: () => {
                    Utils.each(
                        this.files,
                        _ => "delete",
                        c => c.id === id
                    )
                },
            })
        },

        getFiles: function() {
            return new Promise(async (resolve, reject) => {
                const files = this.files

                if (!this.required && files.length <= 0) {
                    return resolve(null)
                }

                if (files.length <= 0) {
                    const msg = (this.title || this.types[this.type]?.name || "文件") + " 不能为空"

                    this.$Message.error(msg)

                    // Callback Error
                    return reject(msg)
                }

                const load = this.$Message.loading("上传文件中...")

                var path = []

                for (let i = 0; i < files.length; i++) {
                    const v = files[i]

                    if (!v.initial) {
                        path.push(v.src)
                        continue
                    }

                    var bucket = await this.getBucket(v).catch(load)

                    const formdata = new FormData()

                    formdata.append("name", bucket.name)
                    formdata.append("host", bucket.host)
                    formdata.append("key", bucket.key)
                    formdata.append("policy", bucket.policy)
                    formdata.append("OSSAccessKeyId", bucket.OSSAccessKeyId)
                    formdata.append("success_action_status", bucket.success_action_status)
                    formdata.append("signature", bucket.signature)
                    formdata.append("file", v.file)

                    // 上传文件
                    await this.uploadFile(bucket.host, formdata).catch(load)

                    path.push(bucket.host + bucket.key)
                }

                load()

                if (this.length === 1 && path.length === 1) {
                    return resolve(path[0])
                }

                resolve(path)
            })
        },

        getBucket(file) {
            return new Promise((resolve, reject) => {
                this.$get("/old/api/common/file/getWebPolicy?bucketName=liefeng")
                    .then(res => {
                        if (res.code == 200) {
                            const data = res.data
                            let now = Utils.getUuid()
                            //防止文件名有.
                            let suffixArr = file.name.split(".")
                            let suffix = "." + suffixArr[suffixArr.length - 1]

                            resolve({
                                name: now + suffix,
                                host: data.host,
                                key: data.key + now + suffix,
                                policy: data.encodedPolicy,
                                OSSAccessKeyId: data.accessId,
                                success_action_status: "200",
                                signature: data.postSignature,
                                url: data.host + data.key + now + suffix,
                            })
                        } else {
                            reject(res.desc)
                        }
                    })
                    .catch(reject)
            })
        },

        /**
         * 上传文件
         */
        uploadFile(url, formdata) {
            return new Promise((resolve, reject) => {
                const request = new XMLHttpRequest()

                request.open("POST", url)

                request.onload = () => {
                    if (request.status === 200) {
                        resolve()
                    } else {
                        reject(request.responseText)
                    }
                }

                request.onerror = reject

                request.send(formdata)
            })
        },
    },
}
</script>

<style lang="less">
@import (reference) "../style/utils.less";

.elem-upload-box {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .upload-input {
        display: none;
    }

    .upload-drag-box {
        position: relative;
        width: 100%;
        padding: 30px 15px;
        background-color: #fff;
        text-align: center;
        cursor: pointer;
        color: #999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #e3e3e3;

        .radius(6px);

        .icon-box {
            .icon {
                font-size: 40px;
            }
        }

        .name {
            margin-top: 10px;
            font-size: 14px;
        }
    }

    .upload-preview-box {
        width: 100%;
        box-sizing: border-box;
        border-left-width: 3px;
        padding: 5px;
        line-height: 20px;
        border: 1px solid #e3e3e3;

        .radius(6px);

        .preview-title {
            margin: 10px 10px 5px 10px;
        }

        .upload-preview-list {
            display: flex;
            flex-wrap: wrap;

            .upload-file-box {
                margin: 5px;
                width: 200px;
                max-width: ~"calc(50% - 12px)";
                display: inline-block;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                border: 1px solid #e3e3e3;

                .radius(6px);

                .upload-image-base,
                .upload-video-base {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .upload-image-base .upload-image {
                    height: 100%;
                }

                .upload-video-base {
                    .taro-video {
                        height: 100%;
                    }

                    .upload-video {
                        max-width: 300px;
                        height: 100px;
                        max-height: 100%;
                    }
                }

                .upload-audio-base {
                    width: 100%;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #eff1f3;

                    .upload-audio {
                        max-width: 150px;
                        height: 52px;
                        max-height: 100%;
                    }
                }

                .upload-file-base {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .upload-file-operating {
                    padding: 0 10px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 1px solid #f3f3f3;

                    .upload-file-operating-button {
                        cursor: pointer;
                        width: 25px;
                        height: 25px;
                        padding: 5px;
                        display: flex;
                        border-radius: 5px;

                        .border-box;

                        &:active {
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                        }
                    }

                    .upload-file-operating-move {
                        display: flex;
                    }

                    .operating-button-disable {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }

                &:first-child .upload-file-operating-move-before,
                &:last-child .upload-file-operating-move-after {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                &:first-child .upload-file-operating-move-before:hover,
                &:last-child .upload-file-operating-move-after:hover {
                    box-shadow: initial;
                }
            }
        }
    }
}
</style>
